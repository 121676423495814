import React, { useState } from 'react';
import { useNotification } from '../utils/NotificationContext';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

function ForgotPasswordPage() {
  const notify = useNotification();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate(); // Hook to navigate programmatically

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage(''); // Clear any previous error messages

    try {
      const response = await fetch('https://financify.anishnayak.com/user/forgotpassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }), // Send email
      });

      const responseBody = await response.json();

      if (!response.ok) {
        setErrorMessage(responseBody.message || 'Password reset failed');
        notify('error', responseBody.message || 'Password reset failed');
        setIsLoading(false);
        return;
      }

      notify('success', 'Password reset link sent');
      setIsLoading(false);
      navigate('/login'); // Redirect to login page after email is sent
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred. Please try again.');
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-gradient-to-r from-blue-50 via-purple-100 to-green-200 min-h-screen flex items-center justify-center px-4 overflow-hidden relative">
      {/* Background Streaks */}
      <div className="absolute inset-0 bg-gradient-to-r from-transparent via-blue-200 to-transparent opacity-30 pointer-events-none z-10"></div>

      <div className="bg-white p-6 md:p-8 rounded-lg shadow-xl w-full max-w-md z-20">
        <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">Forgot Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Email Address
            </label>
            <input
              type="email"
              id="email"
              className="w-full px-3 py-2 border border-gray-300 rounded-md text-gray-900 focus:ring-gray-500 focus:border-gray-500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              aria-label="Email address"
              autoFocus
            />
          </div>
          {errorMessage && (
            <p className="text-sm mb-4 text-red-500 text-center flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 mr-2 text-red-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <circle cx="12" cy="12" r="11" stroke="currentColor" strokeWidth="2" fill="none" />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v6m0 4h.01"
                />
              </svg>
              {errorMessage}
            </p>
          )}
          <button
            type="submit"
            className="w-full bg-black text-white font-semibold py-2 px-4 rounded-md hover:bg-gray-800 focus:ring-2 focus:ring-gray-600 focus:ring-offset-2 focus:outline-none disabled:opacity-50 transition-all duration-300"
            disabled={isLoading}
            onFocus={(e) => e.target.classList.add('ring-2', 'ring-blue-400')}
            onBlur={(e) => e.target.classList.remove('ring-2', 'ring-blue-400')}
          >
            {isLoading ? (
              <div className="spinner mx-auto border-t-4 border-b-4 border-gray-900 border-solid w-6 h-6 rounded-full animate-spin"></div> // Spinner
            ) : (
              'Send Reset Link'
            )}
          </button>
        </form>
        <p className="mt-4 text-sm text-gray-600 text-center">
          Remembered your password?{' '}
          <a href="/login" className="text-gray-900 underline hover:text-gray-700">
            Login
          </a>
        </p>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
