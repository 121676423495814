import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import { NotificationProvider } from './utils/NotificationContext';
import Header from './components/header';
import ProtectedRoute from './components/ProtectedRoute';
import NotFoundPage from './components/404NotFound'; // Import the NotFoundPage
import ForgotPasswordPage from './pages/ForgotPassword';
import ResetPasswordPage from './pages/ResetPassword';
function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <NotificationProvider>
          <Routes>
            {/* Public routes */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<div>Home Page</div>} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
            {/* Protected route */}
            <Route
              path="/dashboard"
              element={<ProtectedRoute element={<div>Dashboard Page</div>} />}
            />
            
            {/* Redirect any unmatched route to /404 */}
            <Route path="*" element={<Navigate to="/404" />} />

            {/* 404 page route */}
            <Route path="/404" element={<NotFoundPage />} />
          </Routes>
        </NotificationProvider>
      </div>
    </Router>
  );
}

export default App;
