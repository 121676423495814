import React, { createContext, useState, useContext } from 'react';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = (type, message) => {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, type, message }]);

    setTimeout(() => {
      setNotifications((prev) => prev.filter((notif) => notif.id !== id));
    }, 3000); // Auto-remove after 3 seconds
  };

  return (
    <NotificationContext.Provider value={addNotification}>
      {children}
      <div className="fixed top-5 right-5 space-y-3 z-50">
        {notifications.map(({ id, type, message }) => (
          <div
            key={id}
            className={`rounded-lg px-4 py-3 shadow-lg font-bold ${
              type === 'success'
                ? 'bg-green-500 text-white'
                : type === 'error'
                ? 'bg-red-500 text-white'
                : type === 'info'
                ? 'bg-blue-500 text-white'
                : 'bg-yellow-500 text-gray-900'
            }`}
          >
            {message}
          </div>
        ))}
      </div>
    </NotificationContext.Provider>
  );
};
